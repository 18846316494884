<template>
    <div class="about">
        <l-header></l-header>
        <img-box img="about1.png"></img-box>
        <img-box img="about2.png" style="margin-top: 53px"></img-box>
        <img-box img="about3.png"></img-box>
        <img-box img="about4.png"></img-box>
        <l-footer></l-footer>
    </div>
</template>

<script>
import LHeader from "@/components/Header";
import ImgBox from "@/components/ImgBox";
import LFooter from "@/components/LFooter";

export default {
    name: "driver",
    components: {
        LHeader, ImgBox, LFooter
    },
    data(){
        return {
            center: {lng: 109.45744048529967, lat: 36.49771311230842},
            zoom: 13
        }
    },
    methods:{
        handler ({BMap, map}) {
            var point = new BMap.Point(109.49926175379778, 36.60449676862417)
            map.centerAndZoom(point, 13)
            var marker = new BMap.Marker(point) // 创建标注
            map.addOverlay(marker) // 将标注添加到地图中
            var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            map.addOverlay(circle)
        },
    }
}
</script>
